@use '~summit-react/src/scss/variables' as v;

.stack {
  .stack-header.card-header {
    background-color: white;
  }

  .stack-actions {
    margin-bottom: 10px;
    .non-destructive {
      .btn {
        margin-right: 5px;
      }
    }
    .destructive {
      text-align: right;
      .btn {
        margin-left: 5px;
      }
    }
  }

  .btn.destroy {
    background-color: v.$red;
  }

  .btn.reset {
    background-color: v.$warning-orange;
  }

  .btn.re-deploy {
    background-color: v.$warning-orange;
  }

  .collapse-control {
    padding: 0 0.25rem 0 0.25rem;
  }
}
