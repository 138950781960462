// "@import" should only be used here at the top level. See "@use" docs and
// comment in CREST-10176 regarding "@import" and whether "@use" is needed to
// apply styles.
@import 'scss/variables'; // DON'T COPY "@import". // Overrides shared variables
@import '~summit-react/src/scss/variables'; // DON'T COPY "@import"
@import '~bootstrap/scss/bootstrap';
@import '~summit-react/src/scss/theme/theme';

html,
body,
#root {
  height: 100%;
  width: 100%;
}

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $background;
}
