@use '~summit-react/src/scss/variables' as v;

.launcher {
  margin-top: 20px;

  .launch {
    .card-header {
      font-size: x-large;
    }
  }

  .spinner {
    text-align: right;
    .card-header {
      font-size: xx-large;
      text-align: center;
    }
  }

  .card-body,
  .card-footer {
    font-size: large;
  }

  .footer-buttons,
  .return-link {
    text-align: right;
  }

  .stacks-link {
    display: flex;
    align-items: center;
    text-align: left;
  }
}
