.manifest {
  .modal-header {
    font-size: x-large;
  }
  .modal-body {
    font-family: 'Menlo', 'Monaco', monospace;
    pre {
      background-color: white;
    }
  }
  .modal-footer {
    padding-bottom: 0;
    padding-top: 0;
  }
}
