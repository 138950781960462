.deployment {
  .services {
    table {
      width: 100%;
      margin-left: 35px;

      .stack-container {
        padding-left: 1rem;
      }
    }
    button {
      padding: 0 2px 0 3px;
    }
  }
  .spinner {
    font-size: xx-large;
    text-align: center;
  }
}
