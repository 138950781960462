.tasks {
  table {
    width: 100%;

    td.single {
      width: 10%;
    }

    td.double {
      width: 20%;
    }

    tbody {
      tr:first-child {
        border-top: 1px solid black;
      }
    }

    .task-actions {
      .btn {
        padding: 0 5px 0 5px;
        margin-right: 5px;
      }
    }
  }
}
