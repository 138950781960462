@use '~summit-react/src/scss/mixins' as m;
@use '~summit-react/src/scss/variables' as v;

.main-router {
  font-family: v.$text-font-family;
  @include m.flex-column-node;

  // Adding flex to the custom-control element in switch of react-router-v6
  .custom-control {
    padding: 0;
    @include m.flex-column-node;
  }
}
