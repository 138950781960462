.machine.accordion {
  .card-header {
    padding: 4px 20px 4px 20px;
    vertical-align: bottom;
    font-weight: lighter;
  }

  .card-header.expanded {
    font-weight: bold;
  }

  .card-body {
    padding: 20px;
  }

  .indicator {
    margin-top: 2px;
    text-align: right;
  }
}
