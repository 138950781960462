@use '~summit-react/src/scss/variables' as v;

.status-icon {
  padding-right: 0.25em;

  .red {
    color: v.$red;
  }

  .blue {
    color: v.$architecture-blue;
  }

  .green {
    color: v.$green;
  }
}
