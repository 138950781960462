.log-modal {
  p.no-messages {
    font-size: larger;
    text-align: center;
  }

  table {
    table-layout: fixed;
    width: 100%;
    tr {
      vertical-align: top;
    }
    th {
      border-bottom: 1px solid black;
    }
    tr.full-message {
      background-color: white;
      td {
        font-family: 'Menlo', 'Monaco', monospace;
        font-size: small;
      }
      p {
        margin-left: 20px;
      }
    }
    th.expander,
    td.expander {
      width: 2%;
    }
    th.timestamp,
    td.timestamp {
      width: 15%;
    }
    th.message,
    td.message {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .modal-body {
    padding-bottom: 0;
  }
  .modal-footer {
    .row {
      height: fit-content;
      width: 100%;
    }
    .col {
      padding: 0 10px 0 10px;
    }
    .checkbox {
      display: flex;
      align-items: baseline;
      input {
        margin-right: 10px;
      }
    }
    .ellipsis {
      display: flex;
      align-items: normal;
      justify-content: center;
    }
    .button {
      display: flex;
      justify-content: right;
    }
  }
  .spinner {
    font-size: xx-large;
    text-align: center;
  }
}
